import React, { useEffect } from 'react';
import './index.css';

const ConvertKit = () => {
    const src = 'https://relentless-artisan-2033.ck.page/4d1d03f935/index.js';
    useEffect(() => {
        if (document.querySelector(`script[src="${src}"]`)) {
            return;
        }
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        script.setAttribute('data-uid', '4d1d03f935');
        document.getElementById('convertKitWrapper').appendChild(script);
    }, []);
	return (
		<div id="convertKitWrapper">
		</div>
	);
}

export default ConvertKit;
