import { merge } from "theme-ui";
import originalTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

const theme = merge(originalTheme, {
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 48
  ],
  styles: {
      p: {
          code: {
              fontSize: '14px'
          },
      }
  }
});

export default theme;