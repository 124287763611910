import React from 'react';
import './index.css';

const CopyableText = ({ children: text }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
    }
    return (
        <span className='copyableTextContainer'>
            <span tabIndex={0} role="link" onKeyDown={copyToClipboard} className='copyableText' onClick={copyToClipboard}>
                { text }
            </span>
        </span>
    )
}

export default CopyableText;